<template>
  <div class="widget widget-categories mb-4 py-1">
    <h3 class="widget-title">Filter artikelen</h3>
    <div class="flex-grow-1">
      <div class="input-group flex-nowrap">
        <div class="input-group-prepend">
          <span class="input-group-text rounded-left" id="search-icon"
            ><i data-feather="search"></i
          ></span>
        </div>
        <input
          v-model="filterText"
          class="form-control rounded-right"
          type="text"
          placeholder="Filter"
          aria-label="Filter"
          aria-describedby="search-icon"
        />
      </div>
    </div>
    <div v-if="section == 'SALE'" class="widget widget-categories py-1 mt-4">
      <h3 class="widget-title">Voorraad</h3>
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          id="ex-check-1"
          value="in_stock"
          v-model="stockFacets"
        />
        <label class="custom-control-label" for="ex-check-1"
          >Op voorraad in de winkel</label
        >
      </div>
      <div class="custom-control custom-checkbox">
        <input
          class="custom-control-input"
          type="checkbox"
          id="ex-check-2"
          value="ext_stock"
          v-model="stockFacets"
        />
        <label class="custom-control-label" for="ex-check-2"
          >Online op voorraad</label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterWidget",
  props: ["section"],
  data: function () {
    return {
      filterText: "",
      stockFacets: [],
    };
  },
};
</script>

<style scoped></style>
