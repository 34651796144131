<template>
  <div class="rental">
    <div class="toast-container toast-top-right">
      <div
        id="add-to-cart-toast"
        data-delay="3000"
        class="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="toast-header bg-primary text-white">
          <i
            class="mr-2"
            data-feather="check-circle"
            style="width: 1.25rem; height: 1.25rem"
          ></i>
          <span class="font-weight-semibold mr-auto">Artikel Toegevoegd</span>
          <button
            type="button"
            class="close text-white ml-2 mb-1"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body text-primary">
          Het Artikel zit in je winkelwagentje.
        </div>
      </div>
    </div>
    <div class="page-title-wrapper pb-0" aria-label="Page title">
      <div class="container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="mt-n1 mr-1"><i data-feather="home"></i></li>
            <li class="breadcrumb-item"><a target="_self" href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a v-if="type == 'RENTAL'" target="_self" href="/verhuur/"
                >Verhuur</a
              >
              <a v-if="type == 'SALE'" target="_self" href="/verkoop/"
                >Verkoop</a
              >
              <a v-if="type == 'OCCASION'" target="_self" href="/occasions/"
                >Occasions</a
              >
            </li>
          </ol>
        </nav>
        <h1 v-if="type == 'RENTAL'" class="page-title">Verhuur</h1>
        <h1 v-if="type == 'SALE'" class="page-title">
          Verkoop <span v-if="sale" class="page-title">aanbiedingen</span>
        </h1>
        <h1 v-if="type == 'OCCASION'" class="page-title">
          Occasions <span v-if="sale" class="page-title">aanbiedingen</span>
        </h1>
        <span class="d-block mt-2 text-muted"></span>
      </div>
    </div>
    <div class="container pb-5 mb-4">
      <DateRangeWidget v-if="type == 'RENTAL'" />
      <div class="row">
        <div class="col-lg-3" style="min-width: 310px">
          <!-- Shop sidebar-->
          <div class="offcanvas-sidebar" ng-class="{'show':class_status }">
            <div class="offcanvas-sidebar-toggle">
              <span class="toggle-knob" ng-click="toggleSingleClass()"
                ><i data-feather="chevrons-right"></i>Categorie &amp;
                filter</span
              >
            </div>
            <div class="offcanvas-sidebar-body">
              <div class="offcanvas-sidebar-body-inner">
                <!--
                <div
                  v-if="type == 'RENTAL'"
                  class="widget widget-categories mb-4 py-1"
                >
                  <h3 class="widget-title">Huurperiode</h3>
                  <div class="flex-grow-1">
                    <div class="input-group flex-nowrap">
                      <LitePicker />
                    </div>
                  </div>
                </div>
                -->
                <FilterWidget ref="filter" :section="type"/>
                <!-- Categories-->
                <CategoryWidget
                  :categories="categories"
                  :valid_cats="valid_cats"
                  :path="catpath"
                  :type="type"
                  :sale="sale"
                />
                <!-- Promo banner-->
                <a
                  class="d-block text-decoration-0"
                  href="/verhuur/artikel/blackmagic-pocket-cinema-camera-6k/"
                  target="_self"
                >
                  <div class="bg-secondary">
                    <div class="px-3 pt-4 text-center">
                      <h4 class="font-size-sm font-weight-normal pt-1 mb-2">
                        Nieuw binnen
                      </h4>
                      <h4 class="h5 pb-2">Blackmagic Pocket 6K</h4>
                      <div class="btn btn-primary btn-sm">Huren</div>
                    </div>
                    <img
                      src="/media/_versions/homepage/banner01_medium.jpg"
                      alt="Promo banner"
                    /></div
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <!-- Products horizontal-->
          <div v-if="type == 'RENTAL'">
            <div
              class="article-item"
              v-for="article in articles"
              :key="article.id"
            >
              <RentalArticle :article="article" :cart="cart" />
            </div>
          </div>
          <div v-if="type != 'RENTAL'" class="row">
            <div
              class="col-md-4 col-sm-6 article-item"
              v-for="article in articles"
              :key="article.id"
            >
              <SalesArticle :article="article" :cart="cart" />
            </div>
          </div>
          <hr class="pb-4 mb-2" />
          <!-- Pagination-->
          <div v-if="has_more" class="justify-content-center d-flex">
            <button
              type="button"
              ng-class="{invisible: articleCtrl.eof}"
              class="btn btn-primary"
              v-on:click="more"
            >
              Laad meer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import feather from "feather-icons";
import Vue from "vue";

import CategoryWidget from "@/components/CategoryWidget.vue";
import FilterWidget from "@/components/FilterWidget.vue";
import RentalArticle from "@/components/RentalArticle2.vue";
import SalesArticle from "@/components/SalesArticle.vue";
import DateRangeWidget from "@/components/DateRangeWidget.vue";
import { cartStore } from "@/stores/cart";

export default {
  name: "ArticleListView",
  props: ["type", "page", "catpath", "q", "sale", "stockfacets"],
  components: {
    FilterWidget,
    CategoryWidget,
    RentalArticle,
    SalesArticle,
    DateRangeWidget,
  },
  data: function () {
    return {
      categories: [],
      articles: [],
      active: null,
      has_more: true,
      editing: false,
      page_size: 12,
      valid_cats: [],
    };
  },
  methods: {
    more() {
      this.$router.push({
        name: this.$route.name,
        params: { catpath: this.catpath },
        query: { page: this.page + 1, q: this.q, stockfacets: this.stockfacets },
      });
    },
    augmentPrices(articles) {
      if (this.type == "RENTAL") {
        let payload = {
          start_date: this.start_date,
          end_date: this.end_date,
          pickup_slot: { slot: this.cart.cart.pickup_slot, custom: false },
          return_slot: { slot: this.cart.cart.return_slot, custom: false },
        };
        payload.lines = _.map(articles, (art) => ({
          article: art,
          count: 1,
        }));
        var headers = {
          "X-CSRFToken": Vue.$cookies.get("csrftoken"),
        };
        axios
          .post("/api/shop/prices/", payload, { headers })
          .then((response) => {
            _.forEach(response.data.rental_lines, (p) => {
              let art = _.find(articles, (art) => p.sam_id == art.sam_id);
              art.rent_total = p.price;
              art.discount = p.discount;
              art.rent_btw = p.btw;
            });
          });
      }
      return articles;
    },
    search(new_offset) {
      var facets = [];
      if (this.sale) {
        facets.push(`sale:${this.sale}`);
      }
      _.forEach(this.stockfacets, (sfacet) => {
        if (sfacet) {
          facets.push(`stock:${sfacet}`);
        }
      });
      var params = {
        params: {
          q: this.q,
          cat: this.catpath,
          start: 0,
          end: new_offset,
          type: this.type,
          facets: facets.join(","),
        },
      };
      axios.get("/api/search/", params).then((response) => {
        this.has_more = response.data[2];
        let articles = _.map(response.data[0], (art) => {
          art.rent_total = null;
          art.rent_btw = null;
          return art;
        });

        _.forEach(articles, (p) => {
          let art = _.find(this.articles, (art) => p.sam_id == art.sam_id);
          if (art) {
            p.rent_total = art.rent_total;
            p.rent_btw = art.rent_btw;
          }
        });

        this.articles = articles;
        this.$nextTick(function () {
          feather.replace();
        });

        // let ids = _.join(_.map(articles, 'os_id'))
        //
        // axios.get("/api/shop/stock/", {params: {ids: ids}}).then((response) => {
        //   console.log(response)
        // });
        console.log(articles);

        var vm = this;
        if (vm.start_date) {
          _.forEach(_.chunk(articles, this.page_size), (arts) => {
            this.augmentPrices(arts);
          });
        }
      });
    },
    fetchData() {
      axios
        .get("/api/shop/categorydump/")
        .then((response) => (this.categories = response.data));
      let params = {
        params: {
          type: this.type,
        },
      };
      axios
        .get("/api/search/", params)
        .then((response) => (this.valid_cats = response.data[1]));
    },
    reloadPrices() {
      _.forEach(this.articles, (art) => {
        art.rent_total = null;
        art.rent_btw = null;
      });
      this.augmentPrices(this.articles);
    },
  },
  created() {
    this.fetchData();
    this.search(this.page * this.page_size);
  },
  computed: {
    start_date: function () {
      return this.cart.cart.start_date;
    },
    end_date: function () {
      return this.cart.cart.end_date;
    },
    pickup_slot: function () {
      return this.cart.cart.pickup_slot;
    },
    return_slot: function () {
      return this.cart.cart.return_slot;
    },
  },
  watch: {
    editing: function () {
      this.$nextTick(function () {
        feather.replace();
      });
    },
    page: function () {
      this.search(this.page * this.page_size);
    },
    q: function () {
      this.$refs.filter.filterText = this.q;
      this.search(this.page * this.page_size);
    },
    stockfacets: function () {
      if (Array.isArray(this.stockfacets)) {
        this.$refs.filter.stockFacets = this.stockfacets;
      } else {
        this.$refs.filter.stockFacets = [this.stockfacets];
      }
      this.search(this.page * this.page_size);
    },
    catpath: function () {
      this.search(this.page * this.page_size);
    },
    start_date: function () {
      this.reloadPrices();
    },
    end_date: function () {
      this.reloadPrices();
    },
    pickup_slot: function () {
      this.reloadPrices();
    },
    return_slot: function () {
      this.reloadPrices();
    },
  },
  mounted() {
    this.$refs.filter.filterText = this.q;
    if (this.stockfacets) {
      if (Array.isArray(this.stockfacets)) {
        this.$refs.filter.stockFacets = this.stockfacets;
      } else {
        this.$refs.filter.stockFacets = [this.stockfacets];
      }
    }
    this.$watch("$refs.filter.filterText", (new_val) => {
      clearTimeout(window.alvtimeout);
      window.alvtimeout = setTimeout(() => {
        this.$router.push({
          name: this.$route.name,
          params: { catpath: this.catpath },
          query: { q: new_val, stockfacets: this.stockfacets },
        });
      }, 500);
    });
    this.$watch("$refs.filter.stockFacets", (new_val, old_val) => {
      if (new_val.toString() != old_val.toString()) {
        console.log(new_val, old_val);
        clearTimeout(window.alvtimeout);
        window.alvtimeout = setTimeout(() => {
          this.$router.push({
            name: this.$route.name,
            params: { catpath: this.catpath },
            query: { q: this.q, stockfacets: new_val },
          });
        }, 500);
      }
    });
  },
  setup() {
    const cart = cartStore();
    return {
      cart,
    };
  },
};
</script>

<style scoped>
.offcanvas-sidebar {
  opacity: 98%;
}
.toggle-knob {
  opacity: 70%;
}
</style>
